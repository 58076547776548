<template>
  <b-modal
    @shown="updateItem"
    title="編輯業務費及行政管理費核銷明細"
    id="operationAdministrationFeeEdit"
    size="xl"
    @hide="doSometing"
  >
    <b-container fluid>
      <section class="search-block">
        <h5 class="big-title">
          {{ editItem.BYear }}年度 第{{ nowSemester == 1 ? "一" : "二" }}學期
          培育學校:
          {{ editItem.school }}
        </h5>
        <div class="dtc-search1">
          <b-button variant="warning" @click="openModal(1)" size="sm"
            ><i class="fas fa-plus"></i>新增</b-button
          >
          <!-- <b-button variant="primary" size="sm" @click="saveFee">儲存</b-button> -->
          <b-button variant="danger" size="sm" @click="openPrintPage"
            >列印</b-button
          >
          <b-input-group prepend="核定補助款">
            <vue-numeric
              currency="NTD$"
              separator=","
              v-model="equipmentFeeSubsidy"
              style="text-align: end;padding-right:5px"
              disabled
            ></vue-numeric>
          </b-input-group>
          <b-input-group prepend="剩餘補助金額">
            <vue-numeric
              currency="NTD$"
              separator=","
              v-model="schoolMoney"
              style="text-align: end;padding-right:5px"
              disabled
            ></vue-numeric>
          </b-input-group>
        </div>
      </section>
      <header class="dtc-grid-header my-dark">
        <div v-for="(item, i) in headers" :key="`headersOne${i}`">
          {{ item.name || item }}
        </div>
      </header>
      <main
        class="dtc-grid-header2"
        v-for="(item, i) in items"
        :key="i"
        style="color:#39312E;"
        :style="
          i % 2 == 0
            ? 'background-color:#ffffff ;'
            : 'background-color: #efefef;'
        "
      >
        <div style="padding:0">
          <b-button
            class="mr-2"
            variant="primary"
            size="sm"
            style="height:32px;line-height:28px"
            @click="openModal(2, item, i)"
            >編輯</b-button
          >
          <b-button
            variant="danger"
            size="sm"
            style="height:32px;line-height:28px"
            @click="deleteFee(i)"
            >刪除</b-button
          >
        </div>
        <div style="padding-top:3px;">
          {{ i + 1 }}
        </div>
        <div :title="item.Category">{{ item.Category || "" }}</div>
        <div :title="item.ItemName">{{ item.ItemName || "" }}</div>
        <div :title="item.SitemName">{{ item.SitemName || "" }}</div>
        <div :title="item.Quantity">{{ item.Quantity || "" }}</div>
        <div :title="item.Unit">{{ item.Unit || "" }}</div>
        <div :title="item.Price" style="text-align:right;padding-right:9px">
          {{ item.Price ? $formatPrice(item.Price) : "" }}
        </div>
        <div :title="item.SubTotal" style="text-align:right;padding-right:9px">
          {{ item.SubTotal ? $formatPrice(item.SubTotal) : "" }}
        </div>
        <div :title="item.Remark" style="border-right:0px">
          {{ item.Remark || "" }}
        </div>
      </main>
      <footer class="dtc-grid-footer">
        <div>總計</div>
        <div style="text-align:right;padding-right:9px">
          {{ totalFee ? $formatPrice(totalFee) : "" }}
        </div>
        <div></div>
      </footer>
      <div>
        <b-modal
          id="feeModal"
          :title="`${editKind == 1 ? '新增' : '編輯'}業務費及行政管理費`"
          size="md"
          header-bg-variant="warning"
        >
          <div class="equip-grid-2">
            <b-input-group prepend="費用別" class="one-row">
              <b-select
                style="text-align-last: left;"
                :options="['業務費', '行政管理費']"
                v-model="addFeeKind"
              ></b-select>
            </b-input-group>
            <b-input-group prepend="項目名稱" class="one-row">
              <b-input
                :list="
                  addFeeKind == '業務費'
                    ? 'my-list-id'
                    : addFeeKind == '行政管理費'
                    ? 'my-list-id2'
                    : ''
                "
                v-model="addFeeName"
              ></b-input>
              <datalist id="my-list-id">
                <option
                  v-for="(item, i) in addOptions1"
                  :key="`addOptions1${i}`"
                  >{{ item }}</option
                >
              </datalist>
              <datalist id="my-list-id2">
                <option
                  v-for="(item, i) in addOptions2"
                  :key="`addOptions2${i}`"
                  >{{ item }}</option
                >
              </datalist>
            </b-input-group>
            <b-input-group prepend="品項說明">
              <b-input
                type="text"
                v-model="addFeeIllustrate"
                style="text-align:left"
              ></b-input>
            </b-input-group>
            <b-input-group prepend="數量">
              <FormatPriceInput
                style="width: 296px;"
                :data="addFeeNumber"
                keyname="addFeeNumber"
                maxdata="999999"
                @update="saveSingleData"
                v-model="addFeeNumber"
                isSingleCheck="true"
              ></FormatPriceInput>
            </b-input-group>
            <b-input-group prepend="單位" class="one-row">
              <b-input
                type="text"
                v-model="addUnit"
                style="text-align:left"
              ></b-input>
            </b-input-group>
            <b-input-group prepend="單價">
              <FormatPriceInput
                style="width: 296px;"
                :data="addFeePrice"
                keyname="addFeePrice"
                maxdata="999999"
                @update="saveSingleData"
                v-model="addFeePrice"
                isSingleCheck="true"
              ></FormatPriceInput>
            </b-input-group>
            <b-input-group prepend="備註說明" class="one-row">
              <b-input
                type="text"
                v-model="addComment"
                style="text-align: left;"
              ></b-input>
            </b-input-group>
          </div>
          <template v-slot:modal-footer>
            <div class="w-100">
              <b-button
                variant="danger"
                class="float-right mr-1"
                @click="$bvModal.hide('feeModal')"
                >取消</b-button
              >
              <b-button
                variant="primary"
                class="float-right mr-1"
                @click="saveFee"
                >儲存</b-button
              >
            </div>
          </template>
        </b-modal>
      </div>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3 mr-3"
          @click="$bvModal.hide('operationAdministrationFeeEdit')"
          >關閉</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
import FormatPriceInput from "./FormatPriceInput";

const headers = [
  "操作",
  "序",
  "費用別",
  "項目名稱",
  "品項說明",
  "數量",
  "單位",
  "單價",
  "合計(數量*單價)",
  "備註說明",
];
export default {
  name: "operationAdministrationFeeEdit",
  data() {
    return {
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],

      headers,
      studentNum: 0,
      equipmentFeeSubsidy: 0,
      schoolMoney: 0,

      addFeeKind: "",
      addFeeName: null,
      addFeeIllustrate: "",
      addFeeNumber: "",
      addUnit: "",
      addFeePrice: "",
      addComment: "",
      addEno: -1,

      editKeyNow: -1,
      editKind: 1,

      seqNumber: -1,
      totalFee: 0,
      items: [],
      editItem: {},
      addOptions1: ["學生輔導費", "事務性支出", "國內旅費"],
      addOptions2: [
        "水費",
        "電費",
        "瓦斯費",
        "大樓清潔費",
        "電梯保養費",
        "協助本計畫人員加班費",
        "補充保險費",
      ],
    };
  },
  components: {
    FormatPriceInput,
  },
  computed: {
    nowSemester() {
      return store.semester;
    },
  },
  methods: {
    saveSingleData(obj) {
      if (obj.isUp) {
        this.$bvToast.toast(`超過上限值，系統將自動帶入上限值`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }

      this[`${obj.keyname}`] = obj.data;
    },
    async saveFee() {
      //editKind 1為新增 2為編輯
      let arr = [...this.items];
      let objItem = {};

      objItem = {
        Category: this.addFeeKind,
        ItemName: this.addFeeName,
        Price: this.addFeePrice,
        Quantity: this.addFeeNumber,
        Remark: this.addComment,
        SeqNo: this.seqNumber,
        SitemName: this.addFeeIllustrate,
        SubTotal: +this.addFeeNumber * +this.addFeePrice,
        Unit: this.addUnit,
      };

      if (this.editKind == 2) {
        objItem.Eno = this.addEno;
        arr.splice(this.editKeyNow, 1, objItem);
      } else {
        arr.push(objItem);
      }

      const obj = {
        SeqNo: this.seqNumber,
        NurseMoneys: [...arr],
      };

      try {
        const url = "NurseMoney/Set";
        await axios.put(url, obj);
        this.getData();
        this.$bvToast.toast(`${this.editKind == 1 ? "新增" : "編輯"}成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.$bvModal.hide("feeModal");
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async deleteFee(key) {
      let arr = [...this.items];
      arr.splice(key, 1);
      const obj = {
        SeqNo: this.seqNumber,
        NurseMoneys: [...arr],
      };
      try {
        const url = "NurseMoney/Set";
        await axios.put(url, obj);
        this.$bvToast.toast(`刪除成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    openModal(key, item, place) {
      this.editKind = key;
      if (key == 1) {
        requestAnimationFrame(() => {
          this.addFeeKind = "";
          this.addFeeName = null;
          this.addFeeIllustrate = "";
          this.addFeeNumber = "";
          this.addUnit = "";
          this.addFeePrice = "";
          this.addComment = "";
        });
      } else {
        requestAnimationFrame(() => {
          this.addFeeKind = item.Category;
          this.addFeeName = item.ItemName;
          this.addFeeIllustrate = item.SitemName;
          this.addFeeNumber = item.Quantity;
          this.addUnit = item.Unit;
          this.addFeePrice = item.Price;
          this.addComment = item.Remark;
          this.addEno = item.Eno;
          this.editKeyNow = place;
        });
      }

      this.$bvModal.show("feeModal");
    },
    doSometing() {
      //   this.$emit("updateRuralProgress");
    },
    updateItem() {
      this.totalFee = 0;
      this.studentNum = 0;
      this.equipmentFeeSubsidy = 0;
      this.schoolMoney = 0;
      this.addFeeKind = "";
      this.addFeeName = null;
      this.addFeeIllustrate = "";
      this.addFeeNumber = "";
      this.addUnit = "";
      this.addFeePrice = "";
      this.addComment = "";
      this.editKind = 1;
      this.seqNumber = -1;
      this.totalFee = 0;
      this.items = [];
      this.editItem = {};
      this.addEno = -1;
      this.editKeyNow = -1;
      this.editItem = { ...store.editItem };
      this.getData();
    },
    async getData() {
      const url = `NurseMoney/Get?schoolcode=${this.editItem.SchoolCode}&byear=${this.editItem.BYear}&term=${this.editItem.Term}&category=N`;

      try {
        const res = await axios.get(url);
        this.totalFee = 0;
        if (res.length) {
          const { nursemoneys, SeqNo, Supply, Surplus } = res[0];
          this.items = [...nursemoneys];
          this.seqNumber = SeqNo;
          this.equipmentFeeSubsidy = Supply;
          this.schoolMoney = Surplus;
          this.items.forEach((s) => {
            this.totalFee += s.SubTotal;
          });
          this.schoolMoney =
            this.equipmentFeeSubsidy - this.totalFee > 0
              ? this.equipmentFeeSubsidy - this.totalFee
              : 0;
        }
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    openPrintPage() {
      const url = `/#/doc13?schoolcode=${this.editItem.SchoolCode}&byear=${this.editItem.BYear}&term=${this.editItem.Term}&category=N`;
      window.open(url);
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.input-group {
  margin-bottom: 10px;
}
.dtc-grid-header,
.dtc-grid-header2,
.dtc-grid-footer {
  // width: 2230px;
  display: grid;
  grid-template-columns: 140px 60px 120px 160px 160px 90px 90px 140px 160px 1fr;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
}
.my-dark {
  > div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
::v-deep .modal-content {
  margin-left: -90px;
  width: 116%;
}
.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
    display: grid;
    max-height: 40px;
    margin-bottom: 12px;
    grid-template-columns: repeat(30, max-content);
    grid-gap: 12px;
    > * {
      max-height: 44px;
    }
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
.btn-sm,
.btn-group-sm > .btn {
  height: 37px;
}
.input-group-text {
  width: 170px;
  background: #0379fd;
  color: #fff;
}

div.number > div > div.input-group-text {
  width: 60px;
  background: #0379fd;
  color: #fff;
}
::v-deep .modal-content {
  margin-left: -130px;
  width: 125%;
}

.dtc-grid-footer {
  grid-template-columns: 960px 160px 241px;
  > div {
    font-weight: 600;
    font-size: 16px;
  }
}
</style>
