var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"dtc-main-section mt-2 mr-2"},[_c('EquipmentPurchases'),_c('section',{staticClass:"search-block"},[_c('h5',{staticClass:"big-title"},[_vm._v(" 「偏鄉護理菁英計畫公費生」-費用預估清單 ")]),_c('div',{staticClass:"dtc-search1"},[_c('b-input-group',{attrs:{"prepend":"培育學校"}},[_c('b-select',{attrs:{"options":_vm.schoolObject,"disabled":_vm.healthCodeCheck},on:{"change":_vm.searchDb},model:{value:(_vm.searchSchool),callback:function ($$v) {_vm.searchSchool=$$v},expression:"searchSchool"}})],1),_c('b-input-group',{attrs:{"prepend":"年度"}},[_c('b-select',{attrs:{"options":_vm.years},on:{"change":_vm.searchDb},model:{value:(_vm.searchYear),callback:function ($$v) {_vm.searchYear=$$v},expression:"searchYear"}})],1),_c('b-input-group',{attrs:{"prepend":"學期"}},[_c('b-select',{attrs:{"options":[
            { value: 1, text: '第一學期(上學期)' },
            { value: 2, text: '第二學期(下學期)' } ]},on:{"change":_vm.searchDb},model:{value:(_vm.searchSemester),callback:function ($$v) {_vm.searchSemester=$$v},expression:"searchSemester"}})],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.searchDb}},[_vm._v("進行查詢")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.clearSearch}},[_vm._v("清除條件")])],1)]),_c('header',{staticClass:"dtc-grid-header my-dark"},_vm._l((_vm.headersOne),function(item,i){return _c('div',{key:("headersOne" + i),attrs:{"title":item.name},on:{"click":function($event){return _vm.sort(item)}}},[_vm._v(" "+_vm._s(item.name)+" "),(item.key)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.sortDesc === null),expression:"item.sortDesc === null"}]},[_c('i',{staticClass:"fas fa-sort"})]):_vm._e(),(item.key)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.sortDesc === false),expression:"item.sortDesc === false"}]},[_c('i',{staticClass:"fas fa-sort-up"})]):_vm._e(),(item.key)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.sortDesc),expression:"item.sortDesc"}]},[_c('i',{staticClass:"fas fa-sort-down"})]):_vm._e()])}),0),_vm._l((_vm.items),function(item,i){return _c('main',{key:i,staticClass:"dtc-grid-header2",staticStyle:{"color":"#39312E"},style:(i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;')},[_c('div',{staticStyle:{"cursor":"pointer","padding-top":"3px"}},[_vm._v(" "+_vm._s(+i + 1)+" ")]),_c('div',{staticStyle:{"padding":"2px 0"}},[_c('b-button',{attrs:{"variant":"warning","size":"sm","disabled":item.StatusName == '未預審' || item.StatusName == '已退回'
            ? false
            : true},on:{"click":function($event){return _vm.setApplication(item)}}},[_vm._v("提出")])],1),_c('div',{staticStyle:{"padding":"2px 0"}},[_c('b-button',{attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.openSubsidyEdit(item)}}},[_vm._v("編輯")])],1),_c('div',{staticStyle:{"padding":"2px 0"}},[_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.openPrintPage(item)}}},[_vm._v("檢視")])],1),_c('div',{style:(item.StatusName == '預審中'
          ? 'color:#43944a'
          : item.StatusName == '已退回'
          ? 'color:red'
          : item.StatusName == '完成核定'
          ? 'color:rgb(18 107 214)'
          : ''),attrs:{"id":("itemOne" + i)}},[_vm._v(" "+_vm._s(item.StatusName || "")+" ")]),(item.StatusName == '完成核定' || item.StatusName == '已退回')?_c('b-tooltip',{staticStyle:{"text-align":"left","min-width":"300px"},attrs:{"target":("itemOne" + i),"placement":"top","variant":item.StatusName == '完成核定' ? 'primary' : 'danger'}},[(item.StatusName == '完成核定')?_c('div',{staticStyle:{"text-align":"left"}},[_vm._v(" 完成核定"),_c('br'),_vm._v("請於"+_vm._s(item.PerAuditComment ? _vm.$twDate4(item.PerAuditComment) : "")+"前函文 ")]):_c('div',{staticStyle:{"text-align":"left","background-color":"#FC0303"}},[_vm._v(" 退回申請"),_c('br'),_vm._v("原因:"+_vm._s(item.RejectComment || "")+" ")])]):_vm._e(),_c('div',[_vm._v(_vm._s(item.ApplyNumber || ""))]),_c('div',[_vm._v(" "+_vm._s(item.applyDate ? _vm.$twDate(item.applyDate) : "")+" ")]),_c('div',{attrs:{"title":item.school}},[_vm._v(_vm._s(item.school || ""))]),_c('div',{attrs:{"title":item.BYear}},[_vm._v(_vm._s(item.BYear || ""))]),_c('div',{attrs:{"title":item.Term}},[_vm._v(" "+_vm._s(item.Term == 1 ? "一" : item.Term == 2 ? "二" : "")+" ")]),_c('div',{staticStyle:{"text-align":"right","padding-right":"2rem"},attrs:{"title":item.Apply_Amt}},[_vm._v(" "+_vm._s(item.Apply_Amt ? _vm.$formatPrice(item.Apply_Amt) : "")+" ")])],1)}),_c('footer',{staticClass:"dtx-footer"},[_c('b-pagination',{staticClass:"mt-2",attrs:{"total-rows":_vm.rowCount,"per-page":_vm.pagingRowPerPage,"aria-controls":"dataTable1","align":"center"},scopedSlots:_vm._u([{key:"page",fn:function(ref){
          var page = ref.page;
          var active = ref.active;
return _c('span',{},[(active)?_c('b',[_vm._v(_vm._s(page))]):_c('i',[_vm._v(_vm._s(page))])])}}]),model:{value:(_vm.currentPageNum),callback:function ($$v) {_vm.currentPageNum=$$v},expression:"currentPageNum"}},[_c('span',{attrs:{"slot":"first-text"},slot:"first-text"},[_c('i',{staticClass:"fas fa-fast-backward"})]),_c('span',{attrs:{"slot":"prev-text"},slot:"prev-text"},[_c('i',{staticClass:"fas fa-step-backward"})]),_c('span',{attrs:{"slot":"next-text"},slot:"next-text"},[_c('i',{staticClass:"fas fa-step-forward"})]),_c('span',{attrs:{"slot":"last-text"},slot:"last-text"},[_c('i',{staticClass:"fas fa-fast-forward"})])]),_c('b-select',{staticClass:"mt-2",attrs:{"options":_vm.rows},model:{value:(_vm.pagingRowPerPage),callback:function ($$v) {_vm.pagingRowPerPage=$$v},expression:"pagingRowPerPage"}}),_c('div',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.totalCountStr))])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }