<template>
  <b-modal
    @shown="updateItem"
    :title="`編輯${nowTableTitle}`"
    id="equipmentRuralPurchases"
    size="xl"
    @hide="doSometing"
  >
    <b-container fluid>
      <section class="search-block">
        <h5 class="big-title">
          {{ itemNow.BYear }}年度 第{{ itemNow.Term == "1" ? "一" : "二" }}學期
          培育學校:
          {{ itemNow.school }}
        </h5>
        <div class="dtc-search1">
          <b-button
            variant="warning"
            @click="openEquipModal(1)"
            size="sm"
            :disabled="
              nowTab == 2 && itemNow.StatusName == '預審核定'
                ? false
                : nowTab == 3 && itemNow.StatusName == '完成核定'
                ? false
                : true
            "
            ><i class="fas fa-plus"></i>新增</b-button
          >
          <!-- <b-button variant="primary" size="sm">儲存</b-button> -->
          <b-button variant="danger" size="sm" @click="openPrintPage"
            >列印</b-button
          >
          <b-input-group prepend="學生人數" v-if="nowTab == 2">
            <b-input
              v-model="studentNum"
              style="width:100px"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="設備費補助款" v-if="nowTab == 2">
            <vue-numeric
              currency="NTD$"
              separator=","
              v-model="fee1"
              style="text-align: end;padding-right:5px"
              disabled
            ></vue-numeric>
          </b-input-group>
          <b-input-group prepend="學校採購總金額" v-if="nowTab == 2">
            <vue-numeric
              currency="NTD$"
              separator=","
              v-model="fee2"
              style="text-align: end;padding-right:5px"
              disabled
            ></vue-numeric>
          </b-input-group>

          <b-input-group prepend="核定補助款" v-if="nowTab == 3">
            <vue-numeric
              currency="NTD$"
              separator=","
              v-model="fee3"
              style="text-align: end;padding-right:5px"
              disabled
            ></vue-numeric>
          </b-input-group>
          <b-input-group prepend="剩餘補助金額" v-if="nowTab == 3">
            <vue-numeric
              currency="NTD$"
              separator=","
              v-model="fee4"
              style="text-align: end;padding-right:5px"
              disabled
            ></vue-numeric>
          </b-input-group>
          <b-input-group prepend="學校自籌款" v-if="nowTab == 3">
            <vue-numeric
              currency="NTD$"
              separator=","
              v-model="fee5"
              style="text-align: end;padding-right:5px"
              disabled
            ></vue-numeric>
          </b-input-group>
        </div>
      </section>
      <header class="dtc-grid-header my-dark">
        <div v-for="(item, i) in headers" :key="`headersOne${i}`">
          {{ item.name || item }}
        </div>
      </header>
      <main
        class="dtc-grid-header2"
        v-for="(item, i) in items"
        :key="i"
        style="color:#39312E;"
        :style="
          i % 2 == 0
            ? 'background-color:#ffffff ;'
            : 'background-color: #efefef;'
        "
      >
        <div style="padding:0">
          <b-button
            class="mr-2"
            variant="primary"
            size="sm"
            style="height:32px;line-height:28px"
            @click="openEquipModal(2, i, item)"
            :disabled="
              nowTab == 2 && itemNow.StatusName == '預審核定'
                ? false
                : nowTab == 3 && itemNow.StatusName == '完成核定'
                ? false
                : true
            "
            >編輯</b-button
          >
          <b-button
            variant="danger"
            size="sm"
            style="height:32px;line-height:28px"
            @click="deleteEquipFee(i)"
            :disabled="
              nowTab == 2 && itemNow.StatusName == '預審核定'
                ? false
                : nowTab == 3 && itemNow.StatusName == '完成核定'
                ? false
                : true
            "
            >刪除</b-button
          >
        </div>
        <div style="padding-top:3px;">
          {{ i + 1 }}
        </div>
        <div>{{ nowTab == 2 ? item.DeviceName : item.ItemName }}</div>
        <div :title="item.Quantity">{{ item.Quantity || "" }}</div>
        <div class="price-content">
          {{
            nowTab == 2 ? $formatPrice(item.PriceFee) : $formatPrice(item.Price)
          }}
        </div>
        <div
          :title="item.SubTotal"
          style="border-right:0"
          class="price-content"
        >
          {{ $formatPrice(item.SubTotal) || "0" }}
        </div>
      </main>
      <footer class="dtc-grid-footer">
        <div>總計</div>
        <div class="price-content" style="padding-right:8px !important">
          {{ $formatPrice(tabTwoTotal) || "0" }}
        </div>
      </footer>
      <div>
        <b-modal
          id="equipModal"
          :title="`${modalType == 1 ? '新增' : '編輯'}設備費採購品目`"
          size="md"
          header-bg-variant="warning"
        >
          <div class="equip-grid-2">
            <b-input-group prepend="設備名稱" class="one-row">
              <b-input
                type="text"
                v-model="editEquipName"
                style="text-align: end;padding-right:20px"
              ></b-input>
            </b-input-group>
            <b-input-group prepend="數量">
              <FormatPriceInput
                style="width: 326px;"
                :data="editEquipNumber"
                keyname="editEquipNumber"
                maxdata="999999"
                @update="saveSingleData"
                v-model="editEquipNumber"
                isSingleCheck="true"
              ></FormatPriceInput>
            </b-input-group>
            <b-input-group prepend="單價">
              <FormatPriceInput
                style="width: 326px;"
                :data="editEquipPrice"
                keyname="editEquipPrice"
                maxdata="999999"
                @update="saveSingleData"
                v-model="editEquipPrice"
                isSingleCheck="true"
              ></FormatPriceInput>
            </b-input-group>
          </div>
          <template v-slot:modal-footer>
            <div class="w-100">
              <b-button
                variant="danger"
                class="float-right mr-1"
                @click="$bvModal.hide('equipModal')"
                >取消</b-button
              >
              <b-button
                variant="primary"
                class="float-right mr-1"
                @click="saveEquipFee"
                >確定</b-button
              >
            </div>
          </template>
        </b-modal>
      </div>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3 mr-3"
          @click="$bvModal.hide('equipmentRuralPurchases')"
          >關閉</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
import FormatPriceInput from "./FormatPriceInput";
const headers = ["操作", "序", "設備名稱", "數量", "單價", "合計"];
export default {
  name: "editRuralProgressRecord",
  data() {
    return {
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      itemNow: {},
      items: [],
      headers,
      studentNum: 0,

      fee1: 0,
      fee2: 0,
      fee3: 0,
      fee4: 0,
      fee5: 0,

      schoolMoney: 0,
      editEquipName: "",
      editEquipNumber: 0,
      editEquipPrice: "",

      editKeyNow: -1,
      editEnoNow: -1,

      modalType: 1,

      seqNumber: "",
      tabTwoTotal: 0,
    };
  },
  components: { FormatPriceInput },
  computed: {
    nowTableTitle() {
      return store.nowTableTitle;
    },
    nowTab() {
      return store.nowTab;
    },
    schoolNameTitle() {
      return store.SchoolName;
      // return "asdf";
    },
  },
  methods: {
    saveSingleData(obj) {
      if (obj.isUp) {
        this.$bvToast.toast(`超過上限值，系統將自動帶入上限值`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }

      this[`${obj.keyname}`] = obj.data;
    },
    searchDb() {},
    openEquipModal(modaltype, key, item) {
      //modalType 1為新增 2為編輯
      this.modalType = modaltype;
      if (modaltype == 1) {
        requestAnimationFrame(() => {
          this.editEquipName = "";
          this.editEquipNumber = 0;
          this.editEquipPrice = "";
        });
      } else if (modaltype == 2 && this.nowTab == 2) {
        requestAnimationFrame(() => {
          this.editKeyNow = key;
          this.editEnoNow = item.Eno;
          this.editEquipName = item.DeviceName;
          this.editEquipNumber = item.Quantity;
          this.editEquipPrice = item.PriceFee;
        });
      } else if (modaltype == 2 && this.nowTab == 3) {
        requestAnimationFrame(() => {
          this.editKeyNow = key;
          this.editEnoNow = item.Eno;
          this.editEquipName = item.ItemName;
          this.editEquipNumber = item.Quantity;
          this.editEquipPrice = item.Price;
        });
      }

      this.$bvModal.show("equipModal");
    },
    async saveEquipFee() {
      //modalType 1為新增 2為編輯
      let arr = [...this.items];
      let objItem = {};
      if (this.nowTab == 2) {
        objItem = {
          DeviceName: this.editEquipName,
          Quantity: this.editEquipNumber,
          PriceFee: this.editEquipPrice,
          SubTotal: +this.editEquipNumber * +this.editEquipPrice,
          Remark: null,
          Purpose: null,
        };
      } else if (this.nowTab == 3) {
        objItem = {
          ItemName: this.editEquipName,
          Quantity: this.editEquipNumber,
          Price: this.editEquipPrice,
          SubTotal: +this.editEquipNumber * +this.editEquipPrice,
          Remark: null,
          Purpose: null,
        };
      }

      if (this.modalType == 2 && this.nowTab == 2) {
        objItem.Eno = this.editEnoNow;
        arr.splice(this.editKeyNow, 1, objItem);
      } else if (this.modalType == 2 && this.nowTab == 3) {
        objItem.Eno = this.editEnoNow;
        arr.splice(this.editKeyNow, 1, objItem);
      } else {
        arr.push(objItem);
      }
      let obj = {};
      if (this.nowTab == 2) {
        obj = {
          SeqNo: this.seqNumber,
          Equipments: [...arr],
        };
      } else {
        obj = {
          SeqNo: this.seqNumber,
          NurseDevices: [...arr],
        };
      }

      try {
        let url = "";
        if (this.nowTab == 2) {
          url = "Budgeting/SetDevices";
        } else if (this.nowTab == 3) {
          url = "NurseDevice/Set";
        }

        await axios.put(url, obj);
        this.getData();
        this.$bvToast.toast(`${this.modalType == 1 ? "新增" : "編輯"}成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.$bvModal.hide("equipModal");
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async deleteEquipFee(key) {
      let arr = [...this.items];
      arr.splice(key, 1);
      let obj = {};
      if (this.nowTab == 2) {
        obj = {
          SeqNo: this.seqNumber,
          Equipments: [...arr],
        };
      } else if (this.nowTab == 3) {
        obj = {
          SeqNo: this.seqNumber,
          NurseDevices: [...arr],
        };
      }

      try {
        let url = "";
        if (this.nowTab == 2) {
          url = "Budgeting/SetDevices";
        } else if (this.nowTab == 3) {
          url = "NurseDevice/Set";
        }
        await axios.put(url, obj);
        this.$bvToast.toast(`刪除成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    doSometing() {
      //   this.$emit("updateRuralProgress");
    },
    updateItem() {
      this.itemNow = { ...store.editItem };
      this.getData();
    },
    async getData() {
      let url = "";
      if (this.nowTab == 2) {
        url = `Budgeting/GetEquipmentItem?schoolcode=${this.itemNow.SchoolCode}&byear=${this.itemNow.BYear}&term=${this.itemNow.Term}&category=N`;
      } else if (this.nowTab == 3) {
        url = `NurseDevice/Get?schoolcode=${this.itemNow.SchoolCode}&byear=${this.itemNow.BYear}&term=${this.itemNow.Term}&category=N`;
      }
      try {
        if (this.nowTab == 2) {
          const {
            equipments,
            TotalPeople,
            DeviceCost,
            DeviceTotal,
            SeqNo,
          } = await axios.get(url);
          this.items = [...equipments];
          this.tabTwoTotal = 0;
          this.studentNum = TotalPeople;
          this.fee1 = DeviceTotal;
          this.fee2 = DeviceCost;

          this.seqNumber = SeqNo;
          this.items.forEach((s) => {
            this.tabTwoTotal += s.SubTotal;
          });
        } else {
          //編輯補助培育學校經費核銷總表
          const {
            SeqNo,
            DeviceTotal,
            DeviceSurplus,
            DeviceSelf,
            nursedevices,
          } = await axios.get(url);

          this.items = [...nursedevices];
          this.tabTwoTotal = 0;
          this.seqNumber = SeqNo;
          this.items.forEach((s) => {
            this.tabTwoTotal += s.SubTotal;
          });

          //總 tabTwoTotal

          //補助
          this.fee3 = DeviceTotal;
          //剩餘補助金額
          this.fee4 =
            this.fee3 - this.tabTwoTotal > 0 ? this.fee3 - this.tabTwoTotal : 0;
          //學校自籌款
          this.fee5 =
            this.tabTwoTotal - this.fee3 > 0 ? this.tabTwoTotal - this.fee3 : 0;
        }
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    openPrintPage() {
      let url = "";
      if (this.nowTab == 2) {
        url = `/#/doc11-1?schoolcode=${this.itemNow.SchoolCode}&byear=${this.itemNow.BYear}&term=${this.itemNow.Term}&category=N`;
      } else if (this.nowTab == 3) {
        url = `/#/doc11?schoolcode=${this.itemNow.SchoolCode}&byear=${this.itemNow.BYear}&term=${this.itemNow.Term}&category=N`;
      }

      window.open(url);
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.input-group {
  margin-bottom: 10px;
}
.dtc-grid-header,
.dtc-grid-header2,
.dtc-grid-footer {
  // width: 2230px;
  display: grid;
  grid-template-columns: 240px 80px 290px 130px 169px 350px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
}
.my-dark {
  > div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
::v-deep .modal-content {
  margin-left: -90px;
  width: 116%;
}
.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
    display: grid;
    max-height: 40px;
    margin-bottom: 12px;
    grid-template-columns: repeat(30, max-content);
    grid-gap: 12px;
    > * {
      max-height: 44px;
    }
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
.btn-sm,
.btn-group-sm > .btn {
  height: 37px;
}
.input-group-text {
  width: 140px;
  background: #0379fd;
  color: #fff;
}

div.number > div > div.input-group-text {
  width: 60px;
  background: #0379fd;
  color: #fff;
}
.dtc-grid-footer {
  grid-template-columns: 909px 349px;
  > div {
    font-weight: 600;
    font-size: 16px;
  }
}
.price-content {
  text-align: right;
  padding-right: 9px !important;
}
</style>
